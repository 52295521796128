.navbar-container {
    max-width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 8em;
    flex-wrap: wrap;
    animation: navbarMove 5s;
    font-size: 1em;
}

@keyframes navbarMove {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.nav-middle {
    color: #707070;
    display: flex;
}

.nav-middle>ul {
    list-style: none;
    text-decoration: none;
    text-decoration-line: none;
    display: flex;
    justify-content:space-between;
    align-items: center;
    width: 35em;
    height: 100%;
    flex-direction: row;
}

.nav-middle-item {
    text-decoration:none;
    color: #707070;

}

.nav-middle-item:hover {
    cursor:pointer;
    color: #c6261e;
}

.samal-logo-container {
    display: flex;
    align-self: center;
}

.samal-logo {
    width: 12.5rem;
    height: 4.5rem;
    margin-bottom: .6em;
}

.navbar-lang {
    display: flex;
    width: 3.8rem;
    align-items: center;
    font-size: .95em;
    font-weight: 500;
    justify-content: space-between;
    color: #707070;
}

.active-lang {
    font-weight: bold;
    color: #c6261e !important;
    border-bottom: #c6261e 2px solid;
}

.navbar-lang:hover {
    cursor: pointer;
}

.not-visibled {
    color: transparent !important;
}

.client-icon {
    width: 1.1em;
    height: 1.1em;
    margin-bottom: 1.1em;
    margin-right: .3em;
}

.client-icon:hover {
    cursor: pointer;
}

.login-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: auto;
    width: 80px;
}

.login-container-clients {
    color: #707070
}

.nav-feature {
    position: absolute;
    font-size: 0.8rem;
    margin-top: 3.5rem;
    padding: 0.3rem 0.6rem 0.4rem 0.6rem;
    border-radius: 20px;
    background-color: #c6261e;
    cursor: pointer;
    color: white;
    margin-left: -0.1rem;
    width: max-content;
    animation: fadePopup 2s;
}


@media only screen and (max-width: 1100px) and (min-width: 768px) {
    .burguer-toogle {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 8em;

    }
    .burguer-toogle-img {
        justify-self: flex-end;
        cursor: pointer;
        opacity: 0.6;
        right: 0;
        width: 2em;
    }
  
    .navbar-lang {
        margin-top: 1.3em;
        font-size: 1em;
        width: 4em;
    }
    .navbar-nav {
        display: flex;
        margin-left: 4em;
        flex-direction: row;
        font-size: 1.2em;
    }
    .samal-logo {
        margin-top: .9em;
    }
}

@media only screen and (max-width: 767px) {
    .burguer-toogle {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        height: 8em;
    }
    .burguer-toogle-img {
        cursor: pointer;
        opacity: 0.6;
        right: 0;
        width: 1.8em;
    }
    .samal-logo {
        width: 180px;
        height: 70px;
        margin-top: 1em;

    }
    .navbar-lang {
        margin-top: 1em;
    }
    .navbar-nav {
        display: flex;
        flex-direction: row;
        font-size: 0.9em;
    }
}


@media only screen and (max-width: 600px) {
    .burguer-toogle {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;
    }
    .burguer-toogle-img {
        position: relative;
        cursor: pointer;
        opacity: 0.6;
        right: 0;
        width: 1.8em;
    }
    .samal-logo {
        width: 150px;
        height: 60px;
    }
    .navbar-lang {
        margin-top: 1em;
    }
    .navbar-nav {
        display: flex;
        flex-direction: row;
        margin-left: 2em;
        font-size: 0.9em;
    }
}

