.popup-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 380px;
  max-height: 60vh;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 27%;
  margin-top: -15%;
  z-index: 1500;
  animation: fadePopup 1s;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));

}

@keyframes fadePopup {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.popup-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #c6261e;
  width: 100%;
  max-height: 2.2em;
  color: white;
  font-weight: bolder;
  font-size: 1rem;
  border-radius: 1px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
}
.popup-header-title {
  position: relative;
  margin-top: 1.1em;
  margin-left: 2em;
}

.popup-header-close-icon {
    position: relative;
    height: 100%;
    cursor: pointer;
    width: 1.4em;
    margin-right: 2em;
}
.popup-body {
  display: flex;
  flex-direction: column;
  width: 110%;
  overflow-y: scroll;
}

.popup-body-wrap{
    background: #fff;
    padding-left: 2rem;
    padding-right: 5rem;
}

.popup-body-wrap::after {
    display: flex;
    content: " ";
    width: 100%;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.popup-country {
  display: flex;
  color: #707070;
  padding-top: 2rem;
}

.popup-country > p {
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 0.5rem;
}
.popup-country > span {
  font-size: 0.7rem;
  font-weight: 100;
  font-style: italic;
}

.popup-info {
  display: flex;
  font-size: 0.9rem;
  justify-content: space-between;
  font-weight: 600;

}

.popup-info-number {
  display: flex;
  font-family: "Helvetica";
  font-weight: 600;
  line-height: 1;
  font-style: normal;
  font-size: 1.4rem;
  color: #c6261e;
  margin-bottom: 0;
}

.popup-info-text {
    display: flex;
}

.popup-icons-container {
    display: flex;
    align-items: flex-start;
}

.popup-icons {
  display: flex;
  width: 2.35rem;
  height: 3.2125rem;
  padding: 0 0.3rem 1rem 0.3rem;
}

.popup-info-anual-shipments {
    margin-left: 1em;
}

.popup-padding-bottom {
    background: #fff;
    height: 2em;
}

@media only screen and (max-width: 768.1px) {
  .popup-container {
    margin-top: 2em;
    position: relative;
    max-height: 45vh;
    min-width: 350px;
    left: 0;
    margin-left: 3em;
    max-width: 80%;
    
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  }
  .mobile-container {
    background: #f7f7f7;
    position: relative;
    height: 100vh;
    display: flex;
    margin-top: 15em;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    cursor: grab;
    padding: 0 auto;
  }
  .countries-header-group {
    width: 50%;
    line-height: 1;
    margin: 2em auto;
  }
  .mobile-header-title {
    font-size: 1.9em;
    font-weight: 300;
    color: #707070;
  }
  .mobile-header-subtitle {
    font-size: 0.9em;
    color: #707070;
    font-weight: 500;
  }
  .card-mobile::-webkit-scrollbar {
    width: 0;
  }
  .card-mobile {
    position: relative;
    display: grid;
    grid-template-columns: repeat(6, 400px);
    grid-gap: 0em;
    overflow-x: scroll;
    margin-right: 1em;

  }
  .countries-header-group > .h1 {
    margin: 2em auto;
    line-height: 1.2;
    max-width: 40%;
    margin-bottom: 1em;
  }
  .popup-title {
    display: flex;
    justify-content: flex-start;
    background-color: #c6261e;
    width: 100%;
    padding: 0;
    align-self: center;
    color: white;
    margin-bottom: 0.5rem;
    font-weight: bolder;
    font-size: 1rem;
    border-radius: 1px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));

  }

  .popup-title-text {
    margin-left: 2em;
    margin-top: .7em;
    margin-bottom: .7em;
  }

  .popup-body-wrap{
    background: #fff;
    padding-left: 2rem;
    padding-right: 2rem;
}

.popup-body-wrap::after {
    width: 90%;
    margin: auto;
    margin-right: 5em;
}

  .popup-country > p {
    font-size: 1.3rem;
    font-weight: bold;
    margin-right: 0.5rem;
  }
  .popup-country > span {
    font-size: 0.6rem;
    font-weight: 100;
    font-style: italic;
  }
}
