.contact-container {
    background-image: url("https://res.cloudinary.com/dpwdm3ip1/image/upload/q_auto:eco,f_auto/v1686603532/home/landing/web/3_kdmccm.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 35%;
    height: 750px;
    margin: auto;
    padding-left: 1.4em;
    padding-right: 2em;
    position: relative;
    display: flex;
    vertical-align: center;

}

.form-container {
    background-color: rgba(255, 255, 255, 0.92);
    display: flex;
    flex-direction: column;
    align-content:center;
    min-width: 48rem;
    margin-top: 3rem;
    padding: 5em;
    margin: auto;
    border-radius: 0.4375rem;
}

.form-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 2.5rem;
    line-height: 1;
}

.form-subtitle {
    font-family: "Roboto";
    margin-top: 1.875rem;
    font-size: 1.5rem;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
}

.contact-btn-container{
    display: flex;
    align-content: center;
    margin-top: 3.125rem;
}

.btn-contact {
    width: 180px;
    margin: 0;
    border-radius: 4px;
    font-family: "Roboto";
    padding: 9px -10px 9px 10px;
    border-radius: 4px;
    background-color: #c81f0e !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.39px;
    color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}

@media only screen and (max-width: 768.1px) {
    .contact-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .form-container {
        background: rgba(223, 223, 223, 0.1);
        -webkit-backdrop-filter: blur(11.9px);
        backdrop-filter: blur(11.9px);
        border-radius: 10px;
        line-height: 1;
        padding: 3.5em;
        border-radius: 7px;
        color: white;
        min-width: 17.5rem;
    }

    .contact-btn-container{
        display: flex;
        margin-top: 1em;
        flex-direction: column;
        align-items: center;
    }

    .form-title {
        font-size: 1.8em;
        word-wrap: break-word;
        width: 100%;
        display: flex;
        color: #fff;
    }
    .form-subtitle {
        font-size: 1.1rem;
        width: 100%;
        color: #fff;
    }

}

@media only screen and (max-width: 321px) {
    .form-container {
        border-radius: 10px;
        margin: 1em;
        padding: 3em;
    }
   
}