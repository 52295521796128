.header-container {
  max-width: 100%;
  height: 720px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.header-body {
  position: absolute;
  height: 700px;
  z-index: 100;
  width: 100%;
}
.imgBg {
  display: none;
}
.videoTag {
  position: absolute;
  top: 0;
  left: 0;
  height: 750px !important;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}
@media only screen and (max-width: 600px) {
  .imgBg {
    position: absolute;
    top: 0;
    left: 0;
    height: 750px !important;
    width: 100%;
    object-fit: cover;
    display: flex;
    pointer-events: none;
  }

  .videoTag {
    display: none;
  }
}
