.contact-btn{
    all: unset;
    background: #C6261E;
    color: white;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 20px;
    margin-left: 30px;
    width: 260px;
    height: 70px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.contact-btn:hover{
    cursor: pointer;
    opacity: 0.8;
}


@media only screen and (max-width: 768.1px) {

    .contact-btn{
        height : 60px;
        font-size: 18px;
        margin-bottom: 1em;
        width: 200px;
        margin-left: 0;
    }
}