

@keyframes loader {

	0% {
		width: 0;
	}

	20% {
		width: 10%;
	}

	25% {
		width: 24%;
	}

	43% {
		width: 41%;
	}

	56% {
		width: 50%;
	}

	66% {
		width: 52%;
	}

	71% {
		width: 60%;
	}

	75% {
		width: 76%;
    
	}

	94% {
		width: 86%;
	}

	100% {
		width: 100%;
	}

}

.progress-bar {
    border-radius: 0px 0px 10px 10px;
	overflow: hidden;
    height: 5px;
    margin: 0 auto;
    left: .06em;
    width: 99.4%;
    position: absolute;
    bottom: 0;
}

.bar {
  background: rgb(216, 216, 216) !important;
}

.progress {
  animation: loader 10s ease infinite;
  background:  #c81f0e;
  color: #fff;
  padding: 5px;
  width: 0;
  height: 0;
}

