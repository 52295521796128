.info-container .exports::after,
.info-container .logistics::before,
.info-container .process::before,
.info-container .seed::before,
.info-container .supervision::before {
    content: "";
    position: absolute;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    height: 700px;
    flex-wrap: wrap;
}

.info-container {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 900px;
    height: 715px;
}

.info-container .article-img {
    width: 80%;
    -o-object-fit: contain;
    object-fit: contain;
    top: 500px;
    margin: 0 auto;
}

.info-container .info-article {
    position: absolute;
    padding: 30px;
    text-align: center;
    width: 250px;
}

.info-container .info-article p {
    margin: 0 8px;
}

.info-container .info-article .info-title {
    font-size: 1.5rem;
    font-weight: bolder;
}

.info-container .info-article .info-text {
    font-size: 1rem;
    margin: 0.7em auto;
}

.info-container>.seed .info-text {
    width: 110px;
    margin: 0.7em auto;
}

.info-container>.logistics .info-text {
    width: 150px;
    margin: 0.7em auto;
}

.info-container>.process .info-text {
    width: 180px;
    margin: 0.7em auto;
}

.info-container>.process-en .info-text {
    width: 150px;
    margin: 0.7em auto;
}

.info-container>.exp-en>.info-text {
    width: 160px;
    margin: 0.7em auto;
}

.info-container .info-article .info-text>strong {
    font-weight: bold;
}

.info-container .supervision {
    top: 150px;
    left: 200px;
}

.info-container .seed {
    bottom: 155px;
    left: -70px;
}

.info-container .process {
    top: 80px;
    right: -80px;
}

.info-container .logistics {
    bottom: 205px;
    right: -70px;
    width: 228px;
}

.info-container .exports {
    bottom: 15px;
    right: 160px;
}

.info-container .supervision::before {
    width: 16%;
    margin-top: -50px;
    margin-left: 120px;
    padding-top: 58px;
    padding-left: 180px;
    border-bottom-right-radius: 30px;
    border-bottom: 2px solid #c71d0e;
    border-right: 2px solid #c71d0e;
}

.info-container .seed::before {
    width: 16%;
    margin-top: -215px;
    margin-left: 0;
    padding-top: 175px;
    padding-left: 140px;
    border-top-left-radius: 30px;
    border-top: 2px solid #c71d0e;
    border-left: 2px solid #c71d0e;
}

.info-container .process::before {
    width: 16%;
    margin-top: 5px;
    margin-left: -230px;
    padding-top: 20px;
    padding-left: 120px;
    border-top-left-radius: 30px;
    border-top: 2px solid #c71d0e;
    border-left: 2px solid #c71d0e;
}

.info-container .logistics::before {
    width: 16%;
    margin-top: -235px;
    margin-left: 110px;
    padding-top: 240px;
    padding-right: 15px;
    border-top: 2px solid #c71d0e;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom: 2px solid #c71d0e;
    border-right: 2px solid #c71d0e;
}

.info-container .exports::after {
    margin-top: -313px;
    margin-left: 0px;
    padding-left: 140px;
    padding-top: 120px;
    width: 16%;
    border-top-left-radius: 30px;
    border-top: 2px solid #c71d0e;
    border-left: 2px solid #c71d0e;
}

.info-container .block {
    border-top-left-radius: 30px;
    border-left: 2px solid #c71d0e;
    border-top: 2px solid #c71d0e;
}


/*MAX WIDTH 1100*/

@media only screen and (max-width: 1100.1px) {
    .info-container>.seed>.info-text {
        width: 105px;
        word-wrap: break-word;
        margin: 0.7 auto;
    }
    .info-container>.exp-en>.info-text {
        width: 160px;
        margin: 0.7em auto;
    }
    .info-container .article-img {
        width: 65%;
    }
    .info-container .supervision {
        top: 180px;
        left: 180px;
    }
    .info-container .seed {
        bottom: 205px;
        left: -20px;
    }
    .info-container .process {
        top: 75px;
        right: 0px;
    }
    .info-container .logistics {
        bottom: 210px;
        right: 0px;
    }
    .info-container .exports {
        bottom: 20px;
        right: 160px;
    }
    .info-container .seed::before {
        margin-top: -135px;
        margin-left: 0;
        padding-top: 105px;
        padding-left: 80px;
    }
    .info-container .supervision::before {
        width: 16%;
        margin-top: -50px;
        margin-left: 110px;
        padding-top: 58px;
        padding-left: 180px;
    }
    .info-container .process::before {
        margin-left: -180px;
        padding-top: 50px;
        padding-left: 80px;
    }
    .info-container .logistics::before {
        margin-left: 80px;
    }
    .info-container .exports::after {
        padding-left: 70px;
        padding-top: 130px;
    }
}


/*MAX WIDTH 899*/

@media only screen and (max-width: 899.1px) {
    .info-container {
        width: 600px;
    }
    .info-container .info-article {
        padding: 10px;
        width: 240px;
    }
    .info-container .article-img {
        width: 80%;
    }
    .info-container .info-article .info-title {
        font-size: 1.3rem;
    }
    .info-container>.seed>.info-title {
        width: 85px;
        margin: auto;
    }
    .info-container .info-article .info-text {
        padding-top: 0.8em;
        font-size: 0.9rem;
        margin: auto;
        width: 150px;
    }
    .info-container>.logistics>.info-text {
        width: 135px;
    }
    .info-container>.seed-en>.info-text {
        width: 121px;
        margin: auto;
    }
    .info-container>.exports>.info-text {
        width: 160px;
        height: 135px;
        margin: auto;
    }
    .info-container .info-article .info-text>strong {
        font-weight: bolder;
    }
    .info-container .supervision {
        top: 180px;
        left: 80px;
    }
    .info-container .supervision::before {
        margin-left: 90px;
        padding-left: 80px;
    }
    .info-container .seed {
        bottom: 230px;
        left: -70px;
    }
    .info-container .seed::before {
        margin-top: -175px;
        padding-top: 145px;
        padding-left: 50px;
    }
    .info-container .process {
        top: 80px;
        right: -60px;
    }
    .info-container .process::before {
        margin-left: -170px;
        padding-top: 50px;
        padding-left: 80px;
    }
    .info-container .logistics {
        bottom: 245px;
        right: -60px;
    }
    .info-container .logistics::before {
        margin-top: -245px;
        padding-top: 250px;
        margin-right: 15px;
    }
    .info-container .exports {
        bottom: 55px;
        right: 120px;
    }
    .info-container .exports::after {
        margin-top: -303px;
        padding-left: 110px;
        padding-top: 120px;
    }
}


/*MAX WIDTH 600*/

@media only screen and (max-width: 768.1px) {
    .info-container {
        position: relative;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        width: 350px;
        height: 700px;
    }
    .info-container .info-article {
        padding: 10px;
        width: 240px;
    }
    .info-container .article-img {
        width: 80%;
        -o-object-fit: contain;
        object-fit: contain;
        top: 00px;
        margin: 0 auto;
    }
    .info-container>.info-article::after {
        content: "";
        position: absolute;
        width: 15%;
        text-align: center;
        border-color: #c71d0e !important;
    }
    .info-container .info-article .info-title {
        font-size: 1.3rem;
    }
    .info-container .info-article .info-text {
        padding-top: 0.8em;
        font-size: 0.9rem;
        margin: auto;
        width: 150px;
    }
    .info-container>.supervision>.info-text {
        width: 120px;
    }
    .info-container>.exp-en>.info-text {
        width: 120px;
    }
    .info-container>.superv-en>.info-text {
        width: 100px;
    }
    .info-container>.log-en>.info-text {
        width: 135px;
    }
    .info-container .info-article .info-text>strong {
        font-weight: bold;
    }
    .info-container .supervision {
        top: 199px;
        left: -10px;
    }
    .info-container .seed {
        top: 50px;
        left: 40px;
    }
    .info-container .process {
        top: 340px;
        right: -52px;
    }
    .info-container .logistics {
        bottom: 10px;
        left: -10px;
    }
    .info-container .exports {
        bottom: -160px;
        right: -52px;
    }
    .info-container .seed::before {
        width: 16%;
        margin-top: 6px;
        margin-left: 65px;
        padding-top: 128px;
        padding-left: 50px;
        border-top-left-radius: 0px;
        border-top-right-radius: 30px;
        border-right: 2px solid #c71d0e;
        border-top: 2px solid #c71d0e;
        border-left: 0;
    }
    .info-container .supervision::before {
        width: 16%;
        margin-left: 90px;
        padding-left: 75px;
    }
    .info-container .supervision::after {
        width: 16%;
        margin-top: -94px;
        margin-left: -115px;
        padding-top: 142px;
        padding-left: 30px;
        border-top-left-radius: 30px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 30px;
        border-right: 0;
        border-bottom: 2px solid #c71d0e;
        border-top: 2px solid #c71d0e;
        border-left: 2px solid #c71d0e;
    }
    .info-container .process::before {
        width: 16%;
        margin-top: 10px;
        margin-left: -250px;
        padding-top: 0;
        padding-left: 60px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        border-top: 2px solid #c71d0e;
        border-left: 0;
        border-bottom: 0;
    }
    .info-container .process::after {
        width: 16%;
        margin-top: -145px;
        margin-left: -113px;
        padding-top: 0;
        padding-left: 50px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        border-top: 2px solid #c71d0e;
        border-left: 0;
        border-bottom: 0;
    }
    .info-container .logistics::before {
        margin-top: -40px;
        margin-left: 70px;
        padding-left: 80px;
        padding-top: 50px;
        width: 16%;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-top: 0;
        border-bottom: 2px solid #c71d0e;
        border-left: 0;
    }
    .info-container .logistics::after {
        margin-bottom: 0;
        margin-top: -108px;
        margin-right: 220px;
        margin-left: -105px;
        padding-left: 0px;
        padding-bottom: 0;
        padding-top: 128px;
        width: 16%;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        border-top: 2px solid #c71d0e;
        border-left: 2px solid #c71d0e;
        border-bottom: 2px solid #c71d0e;
    }
    .info-container .exports::after {
        margin-top: -148px;
        margin-left: -240px;
        padding-left: 155px;
        padding-top: 80px;
        width: 16%;
        border-top-left-radius: 0;
        border-top: 2px solid #c71d0e;
        border-left: 0;
    }
}


/*MAX WIDTH 330*/

@media only screen and (max-width: 334.5px) {
    .info-container {
        width: 300px;
    }
    .info-container .info-article .info-title {
        font-size: 1.1rem;
    }
    .info-container .info-article .info-text {
        padding-top: 0.8em;
        font-size: 0.8rem;
        margin: auto;
        width: 130px;
    }
    .info-container>.superv-en>.info-text {
        width: 95px;
    }
    .info-container>.process>.info-text {
        width: 134px;
    }
    .info-container>.log-en>.info-text {
        width: 120px;
    }
    .info-container>.exp-en>.info-text {
        width: 110px;
    }
    .info-container .supervision {
        top: 199px;
        left: -10px;
    }
    .info-container .seed {
        top: 50px;
        left: 40px;
    }
    .info-container .process {
        top: 340px;
        right: -52px;
    }
    .info-container .logistics {
        bottom: 10px;
        left: -10px;
    }
    .info-container .exports {
        bottom: -160px;
        right: -52px;
    }
    .info-container .seed::before {
        margin-top: 6px;
        margin-left: 44px;
        padding-left: 10px;
        padding-top: 93px;
    }
    .info-container .supervision::before {
        margin-left: 75px;
        padding-left: 55px;
    }
    .info-container .supervision::after {
        margin-left: -105px;
    }
    .info-container .process::before {
        margin-top: 9px;
        margin-left: -190px;
        padding-top: 0;
        padding-left: 0px;
    }
    .info-container .process::after {
        width: 16%;
        margin-top: -145px;
        margin-left: -87px;
    }
    .info-container .logistics::before {
        margin-top: -50px;
        margin-left: 60px;
        padding-left: 40px;
        padding-top: 60px;
        width: 16%;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-top: 0;
        border-bottom: 2px solid #c71d0e;
        border-left: 0;
    }
    .info-container .logistics::after {
        margin-left: -100px;
    }
    .info-container .exports::after {
        margin-top: -148px;
        margin-left: -185px;
        padding-left: 105px;
        padding-top: 80px;
        width: 16%;
        border-top-left-radius: 0;
        border-top: 2px solid #c71d0e;
        border-left: 0;
    }
}

@media only screen and (min-width: 1488.5px) {
    .info-container {
        width: 900px;
        height: 715px;
    }
    .info-container .supervision {
        top: 150px;
        left: 200px;
    }
    .info-container .seed {
        bottom: 155px;
        left: -70px;
    }
    .info-container .process {
        top: 80px;
        right: -80px;
    }
    .info-container .logistics {
        bottom: 205px;
        right: -70px;
        width: 228px;
    }
    .info-container .exports {
        bottom: 15px;
        right: 160px;
    }
}