.nav-mobile-container {
  position: absolute;
  height: 750px;
  width: 300px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: rgba(223, 223, 223, 0.1);
  -webkit-backdrop-filter: blur(11.9px);
  backdrop-filter: blur(11.9px);
  color: #707070;
  z-index: 10000;
}
.nav-mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  height: 8em;
  margin-bottom: .5em;
}
.mobile-close-btn {
    font-size: 1.3em;
    width: 2em;
    font-weight: 300;
    cursor: pointer;
    margin-right: .4em;
}
.mobile-samal-logo {
  width: 180px;
  height: 60px;
  left: 0;
  margin-left: 1em;
}
.nav-mobile {
  display: flex;
}
.nav-mobile-ul {
  list-style-type: none;
  left: 0;
}
.nav-mobile-item {
  margin-top: 0.6em;
  text-align: left;
}
.nav-mobile-link {
  color: #707070;
  font-weight: 500;
  font-family: roboto;
  text-decoration: none;
}

.nav-mobile-link:hover {
  color: #c6261e;
}
.nav-mobile-footer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
}
.nav-mobile-icons {
  margin-bottom: 2em;
  margin-left: 2.3em;
  width: 25%;
  display: flex;
  justify-content: space-around;
}
.nav-mobile-copyright {
  color: #fff;
  text-align: left;
  margin-bottom: 3em;
  margin-left: 2.3em;

  position: relative;
  font-size: 0.9em;
  font-weight: lighter;
}
.nav-mobile-icon {
  color: #fff;
}
.nav-mobile-feature {
  position: relative;
  font-size: 0.8rem;
  padding: 0.1rem 0.6rem 0.1rem 0.6rem;
  border-radius: 20px;
  background-color: #c6261e;
  cursor: pointer;
  color: white;
  margin-left: 0.8em;
  width: max-content;
  animation: fadePopup 2s;
}
@keyframes fadePopup {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 360px) {
  .nav-mobile-container {
    width: 88%;
  }
}
