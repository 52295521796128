.propose-container {
  font-family: "Roboto", sans-serif;
  position: relative;
  height: 700px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #c6261e;
  color: white;
}

.propose-title {
  font-weight: 300;
  font-family: Helvetica, sans-serif;
  font-size: 2.5rem;
  margin-bottom: 4rem;
}

.cards-propose-container {
  position: relative;
  display: flex;
  align-self: center;
  flex-direction: row;
  width: 84%;

}

.propose-gifs-container {
  width: 80%;
  margin: auto;
}
.propose-gifs {
  max-width: 75%;
  height: 11em;
  margin: auto;
}
.card {
    width: 100%;
}
.card-propose-title {
  font-size: 1.5625rem;
  font-weight: bold;
  line-height: 1.32;
  text-align: center;
  color: #fff;
  margin-bottom: 2rem;
}
.card-propose-text {
  font-size: 1.3rem;
  line-height: 1;
  color: #fff;
  max-width: 75%;
  margin: auto;
}

@media only screen and (max-width: 900px) {
  .propose-container {
    height: 100%;
    padding-bottom: 2em;
  }
  .propose-title {
    font-size: 1.8em;
    margin-top: 1.7em;
    margin-bottom: 2rem;
  }
  .cards-propose-container {
    display: flex;
    align-self: center;
    flex-direction: column;
    width: 84%;
  }
  .propose-gifs-container {
    width: 70%;
    margin: auto;
  }
  .propose-gifs {
    max-width: 75%;
    height: 7em;
    margin: auto;
  }
  .card-propose-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.32;
    text-align: center;
    color: #fff;
    margin-bottom: 0.4em;
  }
  .card-propose-text {
    font-size: 1em;
    line-height: 1;
    color: #fff;
    width: 70%;
  }
}
@media only screen and (max-width: 1100px) and (min-width: 768px) {
  .cards-propose-container {
    width: 87%;
  }

  .propose-gifs {
    max-width: 74%;
    height: 8em;
  }
  .card-propose-title {
    font-size: 22px;
  }
  .card-propose-text {
    width: 95%;
    line-height: 1.1;
    font-size: 1.2rem;
  }
}
