.footer-container {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 7.5rem;
  width: 100%;
  padding-left: 2em;
  padding-right: 2em;
  vertical-align: center;
}

.footer-logo {
  width: 120px;
  height: 120px;
}

.footer-icon {
  position: relative;
  font-size: 2rem;
  cursor: pointer;
  margin: 0 0.2em 0 0.2em;
}

.footer-text {
  width: 100%;
  font-size: 1.17em;
  text-align: center;
  align-self: center;
}
.footer-icons-container {
  width: 12.5rem;
  justify-content: space-around;
  display: flex;
  align-items: center;
}


.anchor-footer,
.anchor-footer:link,
.anchor-footer:visited,
.anchor-footer:hover,
.anchor-footer:active {
  color: inherit;
  text-decoration: inherit;
  font-weight: inherit;
}
@media only screen and (max-width: 768.1px) {
  .footer-container {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    padding: 0 0.4em 0 1em;
  }

  .footer-logo {
    width: 86px;
    height: 86px;
  }

  .footer-icon {
    font-size: 1.3em;
    margin: 0;
  }

  .footer-icons-container {
    margin-right: 0.4em;
    justify-content: space-around;
    display: flex;
    align-items: center;
    min-height: 100%;
    width: 120px;
  }
  
  .footer-text {
    font-size: 0.8em;
    text-align: center;
    align-self: center;
  }
}
@media only screen and (max-width: 450px) {
  .footer-text {
    font-size: 0.7em;
    text-align: center;
    align-self: center;
  }
 
  .footer-icon {
    font-size: 1.1em;
    margin: 0;
  }
}
@media only screen and (max-width: 323px) {
    .footer-icons-container {
        margin-right: 0.4em;
        justify-content: space-around;
        display: flex;
        align-items: center;
        min-height: 100%;
        width: 3.75rem;
      }
  .footer-text {
    max-width: 45%;
    padding: 0;
    margin: 0;
    font-size: 0.6em;
    text-align: center;
    align-self: center;
  }
}
