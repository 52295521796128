.success-card-container {
    background-image: url("https://res.cloudinary.com/dpwdm3ip1/image/upload/q_auto:eco,f_auto/v1686603532/home/landing/web/3_kdmccm.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    font-family: roboto;
    background-position: center 35%;
    height: 100vh;
    margin: auto;
    position: relative;
    vertical-align: center;
    border: solid 1px #707070;
}

.success-content-container {
    padding-top: 3em;
    position: relative;
    display: flex;
    flex-direction: column;
    background: #fff;
    height: 80%;
    margin: auto;
    width: 35em;
    margin-top: 5rem;
    margin-left: 5rem;
    padding-left: 5rem;
    border-radius: 5px;
}

.success-card-title {
    font-size: 3.125rem;
    margin-top: .6em;
    font-weight: lighter;
    line-height: 1.14;
    text-align: left;
    color: #333;
}

.success-card-subtitle {
    color: #707070;
    font-weight: 500;
    font-size: 1.7rem;
}

.check-icon {
    align-self: flex-start;
    width: 5rem;
}

.success-card-close-icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.5rem;
    margin: 2rem;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .success-card-container {
        background-image: url("https://res.cloudinary.com/dpwdm3ip1/image/upload/q_auto:eco,f_auto/v1686603532/home/landing/web/3_kdmccm.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 35%;
        border: solid 1px #707070;
        height: 100vh;
        display: flex;
        justify-content: center;
        position: relative;
        vertical-align: center;
    }
    .success-content-container {
        background: rgba(223, 223, 223, 0.1);
        -webkit-backdrop-filter: blur(11.9px);
        backdrop-filter: blur(11.9px);
        border-radius: 10px;
        height: 70%;
        margin: auto;
        padding: 3em;
        display: flex;
        max-width: 25em;
        border-radius: 7px;
        color: white;
    }
    .success-card-title {
        font-size: 2.1em;
        color: #fff;
    }
    .success-card-subtitle {
        font-size: 1.1rem;
        width: 100%;
        color: #fff;
    }
    .check-icon {
        width: 3rem;
        margin-top: 1em;
    }
}