.box {
    z-index: -10;
    position: relative;
    background: rgba(92, 92, 92, 0.178);
    -webkit-backdrop-filter: blur(11.9px);
    backdrop-filter: blur(11.9px);
    border-radius: 10px;
    color: white;
    height: 520px;
    margin-left: 18%;
    width: 450px;
    margin-top: 2em;
    padding: 2em 0 2em 0;
}

.produce_and_exports {
    font-family: "Roboto";
    font-size: 2.5em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding: 0 51px 10px 49px;
}

.about-subtitle {
    font-family: "Roboto";
    font-size: 21px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding: 0 3em 0 2.1em;
}

.btn-container {
    margin: 2em 0 2em 2.8em;
}

.btn-contact {
    border-radius: 4px;
    color: #fff;
    border-radius: 4px;
    background-color: #c81f0e !important;
    font-stretch: normal;
    font-style: normal;
    font-family: sans-serif;
    line-height: 1;
    letter-spacing: 1.39px;
    min-width: 32%;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.4);
}

.btn-contact:hover {
    opacity: 0.9;
    color: white !important;
}

.numbers-container {
    display: flex;
    justify-content: space-around;
    max-width: 100%;
    margin: auto;
    margin-left: 2em;
    margin-right: 2em;
}

.about-numbers {
    font-size: 2em;
    font-family: "Roboto";
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    display: flex;
    justify-content: space-around;
    line-height: 1.15;
    letter-spacing: 0.1em;
    text-align: left;
    color: #fff;
    text-align: left;
}

.about-descr {
    font-family: "Roboto";
    font-size: 0.9rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    max-width: 100%;
}

@media only screen and (max-width: 1100px) {
    .box {
        height: 500px;
        width: 450px;
        margin: 1em auto;
    }
    .produce_and_exports {
        font-size: 2.3em;
        padding: 0.6em 51px 10px 49px;
        width: 450px;
    }
    .about-subtitle {
        font-family: "Roboto";
        font-size: 1.1em;
        padding-left: 2.7em;
        width: 450px;
    }
    .btn-container {
        margin: 2em 0 2em 2.8em;
    }
    .numbers-container{
        display: flex;
        flex-wrap: wrap;
    }
    .about-numbers {
        font-size: 2em;
        line-height: 1;
        margin-bottom: 0.3em;
    }
    .about-descr {
        font-family: "Roboto";
        font-size: 0.8rem;
        font-weight: 700;
    }
}

@media only screen and (max-width: 500px) {
    .box {
        height: 520px;
        width: 350px;
        margin: 0 auto;
    }
    .produce_and_exports {
        font-size: 1.8em;
        padding: 0.8em 51px 10px 49px;
        width: 350px;
    }
    .about-subtitle {
        font-family: "Roboto";
        font-size: 0.88em;
        padding-left: 3.2em;
        width: 350px;
    }
    .btn-container {
        margin: 2.5em 0 2.8em 2.8em;
    }
    .btn-contact {
        width: 160px !important;
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.4);
    }
   
    .numbers-content {
        display: flex;
        flex-direction: column;
        flex-basis: 35%;
    }
    .about-numbers {
        margin: auto;
        font-size: 1.6em;
        text-align: center;
    }
    .about-descr {
        text-align: center;
        margin-top: 0.1em;
        font-size: 0.7rem;
        color: #fff;
    }
}

@media only screen and (max-width: 375px) {
    .box {
        width: 300px;
        height: 500px;
        margin: auto;
    }
    .produce_and_exports {
        font-size: 1.7em;
        padding: 0.5em 11px 10px 30px;
        width: 300px;
    }
    .about-subtitle {
        font-family: "Roboto";
        width: 300px;
        font-size: 0.79em;
        padding-left: 2.2em;
    }
    .btn-container {
        width: 180px;
        padding-right: 2em;
        margin: 2em 0 2em 1.7em;
    }
    .btn-contact {
        min-width: 100%;
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.4);
    }
   
    .about-numbers {
        font-size: 1.6em;
    }
    .about-descr {
        margin-top: 0.1em;
        font-size: 0.7rem;
        color: #fff;
    }
}